import React from 'react';
import Link from 'gatsby-link';

import Image from './Image';


function CarouselItem({ src, to, href }) {
  if (href) {
    return (
      <a href={href} className="stretched-link">
        <Image sm={src.sm} md={src.md} />
      </a>
    );
  }

  if (to) {
    return (
      <Link to={to} className="stretched-link">
        <Image sm={src.sm} md={src.md} />
      </Link>
    );
  }

  return (<Image sm={src.sm} md={src.md} />);
}

CarouselItem.defaultProps = {
  to: null,
};

export default CarouselItem;
