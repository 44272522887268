import React from 'react';
import { Container } from 'reactstrap';

import { Paragraph } from './text';
import AppButtons from './AppButtons';


function AppButtonsBanner() {
  return (
    <Container className="bg-secondary px-3 px-md-6 py-3 py-md-5 d-md-flex align-items-center text-center text-md-left" fluid>
      <Paragraph sm={1} md={2.25} weight={700} className="m-0 mr-md-6 text-dark d-inline-block">
        Download the Subway App
      </Paragraph>
      <AppButtons height={40} />
    </Container>
  )
}

export default AppButtonsBanner;