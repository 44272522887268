import React from 'react';
import Link from 'gatsby-link';
import { Container, Row, Col, Button } from 'reactstrap';
import styled from 'styled-components';

import RightCaret from '../assets/images/Carat.png';
import { Heading2, Heading3 } from './text';

const Background = styled.div`
  background-repeat: no-repeat;
  background-position: ${props => props.position || 'center center'};
  background-size: cover;
  background-image: url(${props => props.backgrounds.sm});
  height: 100vw;
  min-height: 450px;

  @media (min-width: 768px) {
    background-image: url(${props => props.backgrounds.md});
    max-height: 60vh;
    min-height: 600px;
  }
`;


function Hero({ backgrounds, backgroundPosition, heading, subheading, button, className }) {
  return (
    <Background
      className={`container-fluid py-5 px-5 px-md-6 d-flex ${className}`}
      backgrounds={backgrounds}
      position={backgroundPosition}
    >
      <Container className="p-0" fluid>
        <Heading2 sm={1.75} md={4} className="mb-3 mb-md-4">
          SUBWAY <span className="text-secondary">{heading}</span>
        </Heading2>
        <Row>
          <Col md={4}>
            <Heading3 sm={1} md={1.75} className="mb-4 mb-md-9">{subheading}</Heading3>
            <Button
              tag={Link}
              to={button.to}
              className="rounded-pill text-white"
              color="orange"
              block
            >
              {button.caption} <img src={RightCaret} alt="" className="" />
            </Button>
          </Col>
        </Row>
      </Container>
    </Background>
  );
}

Hero.defaultProps = {
  className: 'align-items-center',
};

export default Hero;
